import { ActivityLog } from "@/dto/activity_log/activity_log";
import { useFetchStateStore } from "@/store/fetch-state";

export const useActivityLogStore = defineStore("activityLog", () => {
  const activityLogs = ref<ActivityLog[]>([]);
  const fetchStateStore = useFetchStateStore();

  const getActivityLogs = async () => {
    await fetchStateStore.fetchOnce("activity-logs", async () => {
      activityLogs.value = await $api<ActivityLog[]>(API_ROUTES.activityLog);
    });
  };

  const addLogToStore = (log: ActivityLog) => {
    if (!log) return;
    const index = activityLogs.value.findIndex((l: ActivityLog) => l.id === log.id);
    index !== -1 ? (activityLogs.value[index] = log) : activityLogs.value.unshift(log);
  };

  return { activityLogs, getActivityLogs, addLogToStore };
});
